var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VsSectionHeader',{staticClass:"vs-mb-8",attrs:{"size":"medium","heading":"Storico importazioni"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('VsButton',{staticClass:"vs-flex-auto",attrs:{"to":{
                    name: 'listImportIndexSuppression'
                }}},[_vm._v(" "+_vm._s(_vm.$t('lists.suppression.importSuppressions'))+" ")])]},proxy:true}])}),(_vm.showEmptyState)?_c('VsNotification',{attrs:{"heading":_vm.$t('lists.importHistory.noImports')}}):_vm._e(),(_vm.imports.length > 0)?_c('div',[(_vm.tableColumns.length > 0)?_c('VsTable',{attrs:{"headers":_vm.tableColumns,"items":_vm.imports,"actionColumn":"","fixedAction":"","selectTarget":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"col-status",fn:function(ref){
                var row = ref.row;
return [(row.status)?_c('div',{staticClass:"vs-flex vs-gap-2"},[_c('VsBadge',{attrs:{"aspect":_vm.getStatusAspect(row.status)}},[_vm._v(" "+_vm._s(_vm.$t(("lists.importHistory.status." + (row.status))))+" ")])],1):_vm._e()]}},{key:"bodyActions",fn:function(ref){
                var row = ref.row;
return [_c('VsButton',{staticClass:"vs-p-0",staticStyle:{"color":"var(--vs-color-grey-400)","padding":"0!important"},attrs:{"size":"large","aspect":"ghost","variant":"icon","iconName":"eye","to":{
                        name: 'suppressionImportDetails',
                        params: {
                            importId: row.id,
                        }
                    }},on:{"click":function($event){return _vm.openDetail(row.id)}}})]}}],null,false,3530832244)}):_vm._e(),_c('VsPagination',{staticClass:"vs-mt-8",attrs:{"totalItems":_vm.pagination.totalItems,"expand":"","currentPage":_vm.pagination.currentPage,"itemsPerPage":_vm.pagination.itemsPerPage},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1):_vm._e(),_c('ImportDetailsDrawer',{attrs:{"importId":_vm.importId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }